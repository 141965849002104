export const Contact = {
  /**
   * vars
   */
  src: "https://sdk.form.run/js/v2/embed.js",
  /**
   * funcs
   */
  init: () => {
    let el = document.createElement("script");
    el.src = Contact.src;
    document.getElementById("formrun-script").appendChild(el);
  }
};
