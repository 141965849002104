import { Scroll } from "./scroll";
import { Global } from "./global";

export const PositionHandle = {
  isBgActive: true,
  isTop: true,
  isMiddle: false,
  isBottom: false,
  fixedAreaHandle: () => {
    let targetPos = $(".js-fixedPosition").offset().top;
    let hidePos = $(".js-fixedElmHide").offset().top;
    if (Scroll.position() >= targetPos && hidePos > Scroll.position()) {
      $(".js-fixedTarget").removeClass("is-hide");
      $(".js-fixedTarget").addClass("is-active");
    } else if (hidePos <= Scroll.position()) {
      $(".js-fixedTarget").addClass("is-hide");
    } else if (Scroll.position() < targetPos) {
      $(".js-fixedTarget").removeClass("is-hide");
      $(".js-fixedTarget").removeClass("is-active");
    }
  },
  bgHandle: () => {
    let footerArea = $("#l-footer").offset().top;
    let contentArea = $(".js-bgPauseSmt").offset().top;

    if (Scroll.position() < contentArea) {
      if (PositionHandle.isTop) {
        PositionHandle.isTop = false;
        PositionHandle.isMiddle = true;
        PositionHandle.isBottom = false;
        window.X_GARDEN.bg.resume();
      }
    } else if (
      Scroll.position() >= contentArea &&
      footerArea - 300 > Scroll.position()
    ) {
      if (PositionHandle.isMiddle) {
        PositionHandle.isTop = true;
        PositionHandle.isMiddle = false;
        PositionHandle.isBottom = true;
        window.X_GARDEN.bg.pause();
      }
    } else if (footerArea - 300 <= Scroll.position()) {
      if (PositionHandle.isBottom) {
        PositionHandle.isTop = false;
        PositionHandle.isMiddle = true;
        PositionHandle.isBottom = false;
        window.X_GARDEN.bg.resume();
      }
    }
  }
};
