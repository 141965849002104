/**
 * libs
 *  */
import "./_libs/jquery.easings.min";
import barba from "@barba/core";
// import barbaCss from "@barba/css";
import barbaPrefetch from "@barba/prefetch";
import { gsap } from "gsap";
/**
 * modules
 *  */
import { ua } from "./_module/ua";
import { Global } from "./_module/global";
import { menu } from "./_module/menu";
import { windowSize } from "./_module/windowSize";
import { LogoMotion } from "./_module/headerlogo";
import { PositionHandle } from "./_module/positionhandle";
// import { ParaHandle } from "./_module/parahandle";
import { Locomo } from "./_module/locomo";
import { Cursor } from "./_module/cursor";
// import { ScrollRevealHandle } from "./_module/scrollrevealhandle";
// import "./_module/interaction_app";
// import { Scroll } from "./_module/scroll";
import { scrollHandle } from "./_module/scrollhandle";
/**
 * pages
 *  */
import { Top } from "./_page/index";
import { Contact } from "./_page/contact";
import { Company } from "./_page/company";
// import slick from "slick-carousel";
/**
 * style
 *  */
import "./../style/app.scss";

class Index {
  constructor() {
    this.body = $("body");
    this.window = $(window);
    this.document = $(document);
    this.cursor = $(".cursor");

    this.isMOBILE = "MOBILE";
    this.isPC = "PC";
    this.currentPage = "top";
    this.currentWindowWidth = windowSize.clientSize().W;
    this.isAnchor = true;
    this.isAccess = false;
    if (ua() == "IE") {
      Global.isIE = true;
      alert(
        "※ご覧のWEBブラウザではコンテンツが正常に表示されない可能性があります。(推奨ブラウザ：Google Chrome)"
      );
    } else if (ua() == "MOBILE") {
      Global.isMobile = true;
    }
    this.directory();
  }

  directory() {
    menu.init();
    let page = $("#page").data("page");
    this.pageCurrent(page);
    this.init();
    scrollHandle.noScroll();
    if (page == "Top") {
      if (this.countOpeningTimes() == 1) {
        this.opening();
      } else {
        this.pageStart();
      }
    } else {
      this.pageStart();
    }
  }

  opening() {
    window.X_GARDEN.opening.start(this.pageStart);
  }

  pageStart() {
    let self = this;
    LogoMotion.init();
    gsap.to($("#l-footer"), 0.3, {
      autoAlpha: 1,
      onComplete: () => {
        LogoMotion.lottiePlay();
      }
    });
    $("#mv-ttlBox").addClass("is-active");
    gsap.to($(".wrap"), 1, {
      autoAlpha: 1,
      onStart: () => {
        scrollHandle.returnScroll();
      },
      onComplete: () => {
        if (!Global.isIE) {
          window.X_GARDEN.bg.start();
        }
        gsap.to(".header-trigger", 0.4, {
          autoAlpha: 1
        });
        gsap.to(".m-btnContact", 0.4, {
          autoAlpha: 1
        });
        Locomo.init(self.currentPage);

        if (self.currentPage == "Contact") {
          if (!Global.isIE) {
            window.X_GARDEN.bg.pause();
          }
          gsap.to("#l-footer", 0.3, {
            display: "none",
            opacity: 0
          });
          gsap.to("#cursorBox", 0.3, {
            autoAlpha: 0
          });
        }

        Global.isTrans = true;
      }
    });
  }

  init() {
    this.body.addClass(ua());
    this.setBarba();
    this.scroll();
    // spだったら実行しない↓ あとで
    setTimeout(() => {
      Cursor.cursorEvt();
    }, 1000);
  }

  pageCurrent(page) {
    for (let i = 0, li = Global.lowPageList.length; i < li; i++) {
      if (Global.lowPageList[i] == page) {
        page = page.replace(/-low/g, "");
      }
    }
    $(".header-menuList")
      .find(".-" + page)
      .addClass("is-active");
  }

  setBarba() {
    let self = this;
    barba.use(barbaPrefetch);
    barba.init({
      sync: true,
      transitions: [
        {
          beforeLeave(data) {
            LogoMotion.lottieBack();
            Global.isTrans = false;

            if (data.current.namespace == "Contact") {
              gsap.to("#l-footer", 0.3, {
                display: "block",
                opacity: 1
              });
              gsap.to("#cursorBox", 0.3, {
                autoAlpha: 1
              });
            }
          },
          leave(data) {
            return gsap.to(data.current.container, 1, {
              autoAlpha: 0,
              top: "-8px",
              onStart: () => {
                self.isAccess = true;
                if (!Global.isIE) {
                  window.X_GARDEN.bg.pause();
                }
                // if (data.next.namespace == "Top") {
                //   if (self.countOpeningTimes() == 1) {
                //     let vi = document.getElementById("top-video");
                //     vi.currentTime = 0;
                //     gsap.to("#top_opening", 0.6, {
                //       autoAlpha: 1,
                //       onComplete: () => {
                //         setTimeout(() => {
                //           gsap.to("#top_opening", 0.5, {
                //             autoAlpha: 0
                //           });
                //         }, 2500);
                //       }
                //     });
                //     vi.play();
                //   }
                // }
                window.scrollTo(0, 0);
              },
              onComplete: () => {
                if (!Global.isIE) {
                  window.X_GARDEN.bg.pause();
                }
              }
            });
          },
          afterLeave(data) {
            if (data.next.namespace == "Contact") {
              gsap.to("#l-footer", 0.3, {
                display: "none",
                opacity: 0
              });
              gsap.to("#cursorBox", 0.3, {
                autoAlpha: 0
              });
            }
            window.scrollTo(0, 0);

            Cursor.cursorEvtRemove();
            // クラス等の書き換え
            document.querySelector("#page").dataset.page = data.next.namespace;
            document
              .querySelector("#page")
              .classList.remove(data.current.namespace);
            document.querySelector("#page").classList.add(data.next.namespace);
            gsap.set(data.current.container, {
              position: "absolute",
              top: 0
            });
          },
          beforeEnter(data) {
            window.scrollTo(0, 0);
            Cursor.cursorEvt();
            Locomo.destory();
          },
          afterEnter() {
            setTimeout(() => {
              Global.isTrans = true;
            }, 400);
          },
          enter(data) {
            if (self.currentPage != "Contact") {
              Locomo.init(self.currentPage);
            }

            return gsap.from(data.next.container, 0.6, {
              delay: 1,
              autoAlpha: 0,
              onStart: () => {
                LogoMotion.lottiePlay();
              },
              onComplete: () => {
                $(".header-menuList li").removeClass("is-active");
                self.pageCurrent(data.next.namespace);

                if (self.currentPage != "Contact") {
                  setTimeout(() => {
                    if (!Global.isIE) {
                      window.X_GARDEN.bg.resume();
                    }
                  }, 400);
                } else {
                  window.scrollTo(0, 0);
                  let con = document.getElementById("l-scrollContainer");
                  con.removeAttribute("style");
                }
              }
            });
          }
        }
      ],
      views: [
        //Top
        {
          namespace: "Top",
          // afterEnter(data) {},
          beforeEnter(data) {
            self.currentPage = "Top";
            Top.init();
          },
          afterEnter() {
            // ParaHandle.init();
            // ScrollRevealHandle.init();
            if (self.isAccess) {
              $("#mv-ttlBox").addClass("is-active");
            }
          }
          // afterLeave(data) {
          //   // self.cursorEvtRemove();
          // }
        },
        //Member
        {
          namespace: "Member",
          beforeEnter(data) {
            self.currentPage = "Member";

            // Member.init();
          }
          // afterEnter() {
          //   // ParaHandle.init();
          //   // Locomo.init();
          // },
          // afterLeave(data) {
          //   // self.cursorEvtRemove();
          // }
        },
        // Member-low
        {
          namespace: "Member-low",
          beforeEnter(data) {
            self.currentPage = "Member-low";
          }
          // afterEnter() {},
          // afterLeave(data) {}
        },
        //Works
        {
          namespace: "Works",
          beforeEnter(data) {
            self.currentPage = "Works";
          }
          // afterLeave(data) {}
        },
        // Works-low
        {
          namespace: "Works-low",
          beforeEnter(data) {
            self.currentPage = "Works-low";
          }
          // afterLeave(data) {}
        },
        // News
        {
          namespace: "News",
          beforeEnter(data) {
            self.currentPage = "News";
          }
          // afterLeave(data) {
          //   // self.cursorEvtRemove();
          // }
        },
        // News-low
        {
          namespace: "News-low",
          beforeEnter(data) {
            self.currentPage = "News-low";
          }
          // afterLeave(data) {
          //   // self.cursorEvtRemove();
          // }
        },
        // Company
        {
          namespace: "Company",
          beforeEnter(data) {
            self.currentPage = "Company";
            // Company.init();
          },
          afterEnter() {
            Company.init();
            // ParaHandle.init();
            // Locomo.init();
          }
          // afterLeave(data) {
          //   // self.cursorEvtRemove();
          // }
        },
        //Philosophy
        {
          namespace: "Philosophy",
          beforeEnter(data) {
            self.currentPage = "Philosophy";
            // Philosophy.init();
          }
          // afterEnter() {
          //   // ParaHandle.init();
          //   // ScrollRevealHandle.init();
          // },
          // afterLeave(data) {
          //   // self.cursorEvtRemove();
          // }
        },
        //Contact
        {
          namespace: "Contact",
          beforeEnter(data) {
            self.currentPage = "Contact";
          },
          afterEnter() {
            setTimeout(() => {
              Contact.init();
            }, 500);
          }
          // afterLeave(data) {
          //   // self.cursorEvtRemove();
          // }
        },
        // Contact-low
        {
          namespace: "Contact-low",
          beforeEnter(data) {
            self.currentPage = "Contact-low";
          }
          // afterEnter() {},
          // afterLeave(data) {
          //   // self.cursorEvtRemove();
          // }
        },
        //Service
        {
          namespace: "Service",
          beforeEnter(data) {
            self.currentPage = "Service";
            // Service.init();
          }
          // afterEnter() {
          //   // ParaHandle.init();
          // },
          // afterLeave(data) {}
        }
      ]
    });
    // 同じURLの場合は遷移しない
    const eventDelete = e => {
      if (e.currentTarget.href === window.location.href) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
    };
    const links = [...document.querySelectorAll("a[href]")];
    links.forEach(link => {
      link.addEventListener(
        "click",
        e => {
          eventDelete(e);
        },
        false
      );
    });

    // barba.hooks.enter(() => {
    //   window.scrollTo(0, 0);
    // });
    const replaceHeadTags = target => {
      const head = document.head;
      const targetHead = target.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
      const newPageHead = document.createElement("head");
      newPageHead.innerHTML = targetHead;
      const removeHeadTags = [
        "meta[name='keywords']",
        "meta[name='description']",
        "meta[property^='fb']",
        "meta[property^='og']",
        "meta[name^='twitter']",
        "meta[name='robots']",
        "meta[itemprop]",
        "link[itemprop]",
        "link[rel='prev']",
        "link[rel='next']",
        "link[rel='canonical']"
      ].join(",");
      const headTags = [...head.querySelectorAll(removeHeadTags)];
      headTags.forEach(item => {
        head.removeChild(item);
      });
      const newHeadTags = [...newPageHead.querySelectorAll(removeHeadTags)];
      newHeadTags.forEach(item => {
        head.appendChild(item);
      });
    };
    // gtm
    barba.hooks.after(() => {
      if(window.ga){
        ga('create', 'UA-133012508-1', 'auto');
        ga('send', 'pageview', window.location.pathname);
      }
    });
    // barba.hooks.after(data => {
    //   if (typeof gtag === "function") {
    //     gtag("config", window.GA_MEASUREMENT_ID, {
    //       page_path: window.location.pathname
    //     });
    //   }
    // });
    barba.hooks.beforeEnter(({ next }) => {
      window.scrollTo(0, 0);
      replaceHeadTags(next);
    });
  }

  // resize() {
  //   let setTimeoutId = null;
  //   window.addEventListener("resize", e => {
  //     if (setTimeoutId) clearTimeout(setTimeoutId);
  //     setTimeoutId = setTimeout(() => {
  //       setTimeoutId = null;
  //     }, 1000);
  //   });
  // }

  scroll() {
    let setTimeoutId = null;
    window.addEventListener("scroll", e => {
      if (setTimeoutId) clearTimeout(setTimeoutId);
      setTimeoutId = setTimeout(() => {
        if (this.currentPage == "Top" || this.currentPage == "Philosophy") {
          PositionHandle.fixedAreaHandle();
        }

        if (Global.isMobile) {
          // スマホ時
          PositionHandle.bgHandle();

          // if (Scroll.position() >= 800 && d.delta.y < d.limit.y - 100) {
          //   window.X_GARDEN.bg.pause();
          // } else {
          //   window.X_GARDEN.bg.resume();
          // }
        }

        setTimeoutId = null;
      }, 5);
    });
  }

  getOpeningTimes(c_name) {
    let s;
    let n;
    let m;
    let c_data;
    //cookieの読み込み
    c_data = document.cookie;
    //cookie名
    c_name = c_name + "=";
    //有効なcookie名を調べる
    n = c_data.indexOf(c_name, 0);
    if (n > -1) {
      //cookieのデータ部分を取り出す
      m = c_data.indexOf(";", n + c_name.length);
      if (m == -1) m = c_data.length;
      s = c_data.substring(n + c_name.length, m);
      //デコード
      return unescape(s);
    } else {
      return "";
    }
  }
  countOpeningTimes() {
    let c_name;
    let count;
    //cookie名
    c_name = "visit_top";

    //cookieの読込
    count = this.getOpeningTimes(c_name);
    if (count == "") {
      //データがない場合
      count = 1;
    } else if (count >= 3) {
      count = 1;
    } else {
      //データがある場合
      count++;
    }
    //cookieの保存
    return this.setOpeningTimes(c_name, count, 60);
  }

  setOpeningTimes(c_name, c_data, c_day) {
    let n;
    let c_date;
    let c_limit;
    //有効期限
    c_date = new Date();
    n = c_date.getTime() + 1000 * 60 * 60 * 24 * c_day;
    c_date.setTime(n);
    c_limit = c_date.toGMTString();
    //cookieの書き出し
    document.cookie = c_name + "=" + escape(c_data) + "; expires=" + c_limit;
    return c_data;
  }
}

document.addEventListener("DOMContentLoaded", () => {
  new Index();
});

// window.onpageshow = function(event) {
//   if (event.persisted) {
//     // window.location.reload();
//     if (
//       document.getElementById("Search-detail") != null ||
//       document.getElementById("Taiken-low") != null ||
//       document.getElementById("Top") != null
//     ) {
//       window.location.reload();
//     }
//   }
// };
// window.onpageshow=function(event) {
//   if(event.persisted) window.location.reload();
// };
// window.addEventListener("DOMContentLoaded", () => {
//   new Whale()
// });
