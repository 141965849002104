import LocomotiveScroll from "locomotive-scroll";
// import { Scroll } from "./scroll";
import { Global } from "./global";

export const Locomo = {
  isBgMotionActive: false,
  isPause: true,
  init: page => {
    Global.loco = new LocomotiveScroll({
      el: document.querySelector("[data-scroll-container]"),
      // smooth: Global.isIE == true ? false : true, // true
      // reloadOnContextChange: true,
      smooth: Global.isIE == true ? false : page == "Contact" ? false : true,
      smartphone: {
        // smooth: true
        // smooth: page == "Contact" || page == "Company" ? false : true
        // smooth: page == "Contact" ? false : true,
        smooth: false
        // draggingClass: "has-scroll-dragging"
        // touchMultiplier: 1
      },
      tablet: {
        // smooth: Global.isIE == true ? false : true // true
        smooth: false
        // touchMultiplier: 1
      }
    });

    if (Global.isMobile == false && Global.isIE == false) {
      Global.loco.on("scroll", d => {
        if (d.delta.y >= 800 && d.delta.y < d.limit.y - 380) {
          if (Locomo.isPause) {
            Locomo.isPause = false;
            window.X_GARDEN.bg.pause();
          }
        } else {
          if (!Locomo.isPause) {
            Locomo.isPause = true;
            window.X_GARDEN.bg.resume();
          }
        }
      });
    }

    Global.loco.on("call", d => {
      if (d == "busi" && $(".js-fixedTarget").hasClass("ia-active") == false) {
        $(".js-fixedTarget").addClass("is-active");
      }

      // if (d == "pause") {
      //   if (Global.isTrans) {
      //     // console.log("りさいず");
      //     if (!Global.isBgAnima) {
      //       window.X_GARDEN.bg.pause();
      //     } else {
      //       window.X_GARDEN.bg.resume();
      //     }

      //     Global.isBgAnima = !Global.isBgAnima;
      //   } else {
      //     Global.isBgAnima = false;
      //     console.log("遷移中");
      //   }
      // }

      // if (d == "resume") {
      //   if (Global.isTrans) {
      //     console.log(d);
      //     if (!Global.isBgAnima) {
      //       window.X_GARDEN.bg.pause();
      //     } else {
      //       window.X_GARDEN.bg.resume();
      //     }
      //     Global.isBgAnima = !Global.isBgAnima;
      //   } else {
      //     Global.isBgAnima = false;
      //   }
      // }

      // $(".js-inviewToRight").addClass("ba-ka");
    });
  },
  destory: () => {
    Global.loco.destroy();
  }
};
