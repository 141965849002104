export const Global = {
  root: "/",
  page: "",
  lowPageList: ["Member-low", "Works-low", "News-low", "Contact-low"],
  isMobile: false,
  isIE: false,
  isEDGE: false,
  isBg: false,
  // isBgScroll: false,
  isBgAnima: true,
  isLogoAnima: false,
  loco: null,
  isTrans: false // 遷移時 false 遷移終了 true
};
