import { windowSize } from "./../_module/windowSize";
// import { Scroll } from "./../_module/scroll";

import slick from "slick-carousel";
// import Swiper from "swiper";
// import "swiper/swiper-bundle.css";
// import SwiperCore, { Navigation, Pagination } from "swiper/core";
// SwiperCore.use([Navigation, Pagination]);

export const Top = {
  /**
   * vals
   */
  currentSlide: 0,
  clientWidth: 0,
  mvSlide: [],
  isNavi: false,
  /**
   * funcs
   */
  init: () => {
    // Top.setTitle();
    Top.seWorksSlick();
    Top.setMvHeightSp();
  },
  setSwiper: () => {},
  seWorksSlick: () => {
    $(".works-list")
      .not(".slick-initialized")
      .slick({
        dots: false,
        infinite: true,
        arrows: true,
        slidesToShow: 1,
        autoplay: false,
        speed: 800,
        centerMode: true,
        // centerPadding: "25%",
        centerPadding: "20%",
        fade: false,
        cssEase: "cubic-bezier(0.770, 0.000, 0.175, 1.000)",
        draggable: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        pauseOnDotsHover: false,
        waitForAnimate: false,
        prevArrow:
          '<div class="mod-arrow prev-arrow js-hover-green01"><span class="mod-circ"></span><span class="mod-arr mod-arrL"></span></div>',
        nextArrow:
          '<div class="mod-arrow next-arrow js-hover-green01"><span class="mod-circ"></span><span class="mod-arr mod-arrR"></span></div>',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              centerPadding: "10%",
              arrows: false
            }
          }
        ]
      })
      .on("beforeChange", function(event, slick, currentSlide, nextSlide) {
        // console.log(currentSlide);
        // console.log(nextSlide);
        // $(".slick-slide").removeClass("-current");
        // $(".slick-slide")
        //   .eq(nextSlide)
        //   .addClass("-current");
      })
      .on("afterChange", function(event, slick, currentSlide) {
        Top.currentSlide = currentSlide;
      });
  },
  setMvHeightSp: () => {
    if (!windowSize.device()) {
      $("#block-mv").css("height", windowSize.clientSize().H);
      if (Top.clientWidth != windowSize.clientSize().W) {
        $("#block-mv").css("height", windowSize.clientSize().H);
        Top.clientWidth = windowSize.clientSize().W;
      }
    }
  }
};
