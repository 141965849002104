import { gsap } from "gsap";
import { LogoMotion } from "./headerlogo";
import { Global } from "./global";
// import { scrollHandle } from "../_module/scrollHandle";

export const menu = {
  header: $("#l-header"),
  headerMenu: $(".header-menu"),
  headerMenuInner: $(".header-menuInner"),
  headerItem: $(".header-menuListItem a"),
  trigger: $(".header-trigger"),
  active: "is-active",
  isOpen: false,
  isTransition: false,
  init: () => {
    menu.click();
  },
  click: () => {
    menu.headerItem.on("click", function() {
      if (
        $(this)
          .parent()
          .hasClass(menu.active)
      ) {
        gsap.to("main", 0.4, {
          autoAlpha: 1,
          top: 0,
          onStart: () => {
            // LogoMotion.lottieGoToAndStop(50);
            LogoMotion.lottiePlay();
          }
          // onComplete: () => {
          //   LogoMotion.lottiePlay();
          // }
        });
      }
      gsap.to(menu.headerMenu, 0.2, {
        autoAlpha: 0,
        onComplete: () => {
          menu.isOpen = false;
          menu.isTransition = false;
          menu.header.removeClass(menu.active);
          menu.headerMenuInner.removeClass(menu.active);
        }
      });
    });

    menu.trigger.on("click", () => {
      if (!menu.isTransition) {
        menu.isTransition = true;
        if (!menu.isOpen) {
          LogoMotion.lottieBack();
          gsap.to("main", 0.4, {
            // autoAlpha: 0,
            top: "-8px",
            onComplete: () => {
              menu.headerMenuInner.addClass(menu.active);
            }
          });
          menu.header.addClass(menu.active);
          gsap.to(menu.headerMenu, 0.4, {
            delay: 0.3,
            autoAlpha: 1,
            onComplete: () => {
              // LogoMotion.lottieGoToAndStop(0);
              gsap.set("main", {
                autoAlpha: 0
              });
              menu.isOpen = true;
              menu.isTransition = false;
            }
          });
        } else {
          gsap.to("main", 0.4, {
            autoAlpha: 1,
            top: 0,
            onStart: () => {
              // LogoMotion.lottieGoToAndStop(50);
              LogoMotion.lottiePlay();
            },
            onComplete: () => {
              menu.headerMenuInner.removeClass(menu.active);
              // LogoMotion.lottiePlay();
            }
          });
          menu.header.removeClass(menu.active);

          gsap.to(menu.headerMenu, 0.2, {
            autoAlpha: 0,
            onComplete: () => {
              menu.isOpen = false;
              menu.isTransition = false;
            }
          });
        }
      }
    });
  }
};
