// import { TimelineMax } from 'gsap';
import { global } from "./global";
import { menu } from "./menu";
import { windowSize } from "./windowSize";

export const Scroll = {
  duration: 1.1,
  ease: "Expo.easeOut",
  inview: $(".js-inview"),
  block: $(".block"),
  show: "is-show",
  active: "is-active",
  posArray: [],
  blockOffsetArray: [],
  heightAdjustValue: 0.78,
  init: () => {
    // Scroll.stackBlockPosition();
    // Scroll.scrollEvt();
    // Scroll.current();
    // Scroll.inviewHandle();
  },
  scrollEvt: () => {
    let setTimeoutId = null;
    window.addEventListener("scroll", e => {
      if (setTimeoutId) clearTimeout(setTimeoutId);
      setTimeoutId = setTimeout(() => {
        Scroll.inviewHandle();
        Scroll.current();
        setTimeoutId = null;
      }, 10);
    });
  },
  inviewHandle: () => {
    // block
    for (let i = 0, li = Scroll.posArray.length; i < li; i++) {
      let elTop = Scroll.posArray[i];
      if (
        Scroll.position() +
          windowSize.clientSize().H * Scroll.heightAdjustValue >
        elTop
      ) {
        if (Scroll.inview.eq(i).hasClass(Scroll.isShow)) continue;
        Scroll.inview.eq(i).addClass(Scroll.show);
      }
    }
  },
  current: () => {
    if (global.isTransition) {
      for (var i = Scroll.blockOffsetArray.length - 1; i >= 0; i--) {
        if (Scroll.position() >= Scroll.blockOffsetArray[i]) {
          menu.currentSection(i);
          break;
        }
      }
    }
  },
  position: () => {
    return (
      document.documentElement.scrollTop ||
      document.body.parentNode.scrollTop ||
      document.body.scrollTop
    );
  },
  stackBlockPosition: () => {
    Scroll.posArray = [];
    for (let i = 0, li = Scroll.inview.length; i < li; i++) {
      Scroll.posArray.push(Scroll.inview.eq(i).offset().top);
    }
    // section top position
    global.anchor_item.each(function(i) {
      let blockID = $(this)
        .find("a")
        .attr("href");
      Scroll.blockOffsetArray[i] = $(blockID).offset().top;
      // 最後のセクションの末尾のoffset.top値をendプロパティに設定
      if (i === global.anchor_item.length - 1) {
        Scroll.blockOffsetArray["end"] =
          $(blockID).offset().top + $(blockID).height();
      }
    });
  }
};
