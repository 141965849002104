// import { TweenMax, TimelineMax } from "gsap";
import lottie from "lottie-web";
import { Global } from "./global";

export const LogoMotion = {
  // titleLeadTl: new TimelineMax(),
  active: "is-active",
  init: () => {
    LogoMotion.setLottie();
    // LogoMotion.ready();
  },
  setLottie: () => {
    LogoMotion.logo = lottie.loadAnimation({
      container: document.getElementById("header-logo-lot"),
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: "/data/logo_in.json"
    });
  },
  ready: () => {
    LogoMotion.lottiePlay();
  },
  lottiePlay: () => {
    if (!Global.isLogoAnima) {
      Global.isLogoAnima = true;
      LogoMotion.logo.setDirection(0);
      LogoMotion.logo.play();
    }
  },
  lottieBack: () => {
    if (Global.isLogoAnima) {
      Global.isLogoAnima = false;
      LogoMotion.logo.setDirection(-1);
      LogoMotion.logo.play();
    }
  },
  lottieGoToAndStop: val => {
    // LogoMotion.logo.goToAndStop(val);
    LogoMotion.logo.playSegments([1, val]);
  }
  // lottieGoToAndPlay: () => {
  //   LogoMotion.logo.goToAndPlay();
  // }
};
