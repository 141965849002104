import { Global } from "./../_module/global";

export const Company = {
  /**
   * vars
   */
  src:
    "http://maps.google.com/maps/api/js?key=AIzaSyBZJonY5Ub83UKpgchX5lv5wHFs5wPz4Oc&language=ja",
  // icon: "/images/company/map_pin.png",
  icon: "/images/company/map_pin.svg",
  /**
   * funcs
   */
  init: () => {
    // let el = document.createElement("script");
    // el.src = Company.src;
    // document.body.appendChild(el);
    setTimeout(() => {
      if (Global.isIE) {
        Company.setMapIE();
      } else {
        Company.setMap();
      }
    }, 1000);
  },
  setMapIE: () => {
    let html =
      "" +
      "<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.715073485396!2d139.7034283152581!3d35.65939098019939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b592776bfeb%3A0x7dc8c7db24e06730!2z44CSMTUwLTAwMDIg5p2x5Lqs6YO95riL6LC35Yy65riL6LC377yS5LiB55uu77yR77yU4oiS77yR77yTIOWyoeW0juODk-ODqw!5e0!3m2!1sja!2sjp!4v1618383592866!5m2!1sja!2sjp' , width='600', height='450', style='border:0;', allowfullscreen='', loading='lazy'></iframe>" +
      "";
    $("#map").append(html);
  },
  setMap: () => {
    let MyLatLng = new google.maps.LatLng(35.659391, 139.705617);
    let Options = {
      zoom: 16, //地図の縮尺値
      center: MyLatLng, //地図の中心座標
      mapTypeId: "roadmap", //地図の種類
      icon: Company.icon,
      streetViewControl: false,
      mapTypeControl: false, //マップタイプ コントロール
      fullscreenControl: false,
      styles: [
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            {
              hue: "#ffa800"
            },
            {
              gamma: 1
            }
          ]
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [
            {
              hue: "#a2ff00"
            },
            {
              saturation: 33.4
            },
            {
              lightness: -25.4
            },
            {
              gamma: 1
            }
          ]
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [
            {
              hue: "#53FF00"
            },
            {
              saturation: -73
            },
            {
              lightness: 40
            },
            {
              gamma: 1
            }
          ]
        },
        {
          featureType: "road.arterial",
          elementType: "all",
          stylers: [
            {
              hue: "#FBFF00"
            },
            {
              gamma: 1
            }
          ]
        },
        {
          featureType: "road.local",
          elementType: "all",
          stylers: [
            {
              hue: "#00FFFD"
            },
            {
              lightness: 30
            },
            {
              gamma: 1
            }
          ]
        },
        {
          featureType: "transit",
          elementType: "geometry.fill",
          stylers: [
            {
              visibility: "simplified"
            }
          ]
        },
        {
          featureType: "transit",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "on"
            }
          ]
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified"
            }
          ]
        },
        {
          featureType: "transit.line",
          elementType: "labels",
          stylers: [
            {
              visibility: "on"
            }
          ]
        },
        {
          featureType: "transit.station",
          elementType: "labels",
          stylers: [
            {
              visibility: "simplified"
            }
          ]
        },
        {
          featureType: "transit.station.rail",
          elementType: "labels.text.fill",
          stylers: [
            {
              visibility: "simplified"
            }
          ]
        },
        {
          featureType: "transit.station.rail",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#676565"
            },
            {
              visibility: "on"
            }
          ]
        },
        {
          featureType: "transit.station.rail",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "on"
            },
            {
              color: "#b7b2b2"
            }
          ]
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            {
              hue: "#00BFFF"
            },
            {
              saturation: 6
            },
            {
              lightness: 8
            },
            {
              gamma: 1
            }
          ]
        }
      ]
    };
    let map = new google.maps.Map(document.getElementById("map"), Options);

    let markerOptions = {
      map: map,
      // size: new google.maps.Size(72, 82),
      position: { lat: 35.659391, lng: 139.705617 },
      icon: {
        url: Company.icon
        // scaledSize: new google.maps.Size(36, 41)
      }
    };

    new google.maps.Marker(markerOptions);
  }
};
