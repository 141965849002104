import { gsap } from "gsap";
import { LogoMotion } from "./headerlogo";
import { Global } from "./global";
// import { scrollHandle } from "../_module/scrollHandle";

export const Cursor = {
  cursor: $(".cursor"),
  cursorEvtRemove: () => {
    document.removeEventListener("mousemove", Cursor.cursorDef, true);
    document.removeEventListener("mouseover", Cursor.cursorAddType01, true);
    document.removeEventListener("mouseout", Cursor.cursorRemoveType01, true);
  },
  cursorDef: e => {
    let cursor = $(".cursor");
    gsap.to(cursor, 0.8, { x: e.clientX + 30, y: e.clientY + 30 });
  },
  cursorAddType01: e => {
    let cursorDot = $(".cursor-dot");
    cursorDot.addClass("js-green01");
  },
  cursorRemoveType01: e => {
    let cursorDot = $(".cursor-dot");
    cursorDot.removeClass("js-green01");
  },
  cursorAddType02: e => {
    let cursorDot = $(".cursor-dot");
    cursorDot.addClass("js-green02");
  },
  cursorRemoveType02: e => {
    let cursorDot = $(".cursor-dot");
    cursorDot.removeClass("js-green02");
  },
  cursorEvt: () => {
    gsap.to(Cursor.cursor, 1, { delay: 1, autoAlpha: 1 });
    document.addEventListener("mousemove", Cursor.cursorDef, true);
    let green01 = document.querySelectorAll(".js-hover-green01");
    let green02 = document.querySelectorAll(".js-hover-green02");
    for (let i = 0, li = green01.length; i < li; i++) {
      green01[i].addEventListener("mouseover", Cursor.cursorAddType01, true);
      green01[i].addEventListener("mouseout", Cursor.cursorRemoveType01, true);
    }
    for (let i = 0, li = green02.length; i < li; i++) {
      green02[i].addEventListener("mouseover", Cursor.cursorAddType02, true);
      green02[i].addEventListener("mouseout", Cursor.cursorRemoveType02, true);
    }
  }
};
